/* Header & Nav */
#navbar {
    background-color: black;
}

#navbar .nav-link {
    color: white;
}

#navbar.navbar {
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    --bs-navbar-toggler-border-color: white;
}

@media (min-width: 992px) {
    #navbar {
        background-color: transparent;
        height: 62px;
    }
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-image: var(--bs-navbar-toggler-icon-bg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

#navbar .dropdown-menu {
    background-color: black;
}

#navbar .dropdown-menu .dropdown-item {
    color: white;
    cursor: pointer;
}

#navbar .dropdown-menu .dropdown-item:hover {
    color: white;
    background-color: black;
}


@media (min-width: 992px) {
    #navbar .dropdown-menu {
        background-color: var(--bs-dropdown-bg);
    }

    #navbar .dropdown-menu .dropdown-item {
        color: var(--bs-dropdown-link-color);
    }

    #navbar .dropdown-menu .dropdown-item:hover {
        color: var(--bs-dropdown-link-hover-color);
        background-color: var(--bs-dropdown-link-hover-bg);
    }
}


/* Main content area */
#main {
    position: relative;
    top: -62px;
}

#main section h2 {
    padding-bottom: 1.5rem;
    color: #082a4d;
    font-weight: bold;
}

#main section {
    padding-top: 4rem;
    padding-bottom: 4rem;
}


/* Background img */
.bg-img {
    background-size: cover;
    background-position: center;
}


/* Hero block */
#hero-container {
    padding: 0;
    margin: 0;
}

#hero-inner {
    min-height: 500px;
    background-image: linear-gradient(to right bottom, #051937, #004d7a, #008793, #00bf72, #a8eb12);
    margin-bottom: 150px;
}

#hero-box {
    position: relative;
    bottom: -80px;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

@media (min-width: 768px) {
    #hero-box {
        position: relative;
        bottom: -120px;
    }
}


/* Timeline */
.timeline-item .date-box {
    min-width: 120px;
}

@media (min-width: 992px) {
    .timeline-item .date-box {
        width: 140px;
    }
}

.timeline-item .item-title,
.timeline-item .item-desc {
    max-width: 700px;
}

.timeline-item:not(:last-child) {
    margin-bottom: 2rem;
}


/* Skills */
.progress,
.progress-stacked {
    border-radius: 0;
}

.skill-item {
    margin-bottom: 1.5rem;
    display: flex;
    flex-flow: column;
    justify-content: end;
}

#skills .progress-bar {
    background-image: linear-gradient(to right, #051937, #004d7a, #008793, #00bf72, #a8eb12);
}

#skills .nav-link {
    color: var(--bs-body-color);
}

#skills .nav-link.active {
    color: #fff;
    background-color: #082a4d;
    ;
}

a[data-bs-toggle="popover"],
.fa-circle-info {
    color: #082a4d;
}

/* Projects */
#projects {
    background-image: linear-gradient(to right bottom, #051937, #004d7a, #008793, #00bf72, #a8eb12);
}

.glass-box {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

/*  Change container max size
    This is to overwrite default BS
*/
@media (min-width: 1400px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1080px;
    }
}

@media (min-width: 1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1080px;
    }
}